import React from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  container: {
    padding: 5,
    display: "flex",
    flexDirection: "column",

    "& .MuiInputLabel-animated": {
      transition: "none",
    },

    "& .MuiInputLabel-formControl": {
      position: "initial",
      transform: "none",
      margin: "12px 0px 8px 0px",
      fontWeight: "bold",
      color: "#505050",
      cursor: "pointer",
    },
  },

  input: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      fontSize: 14,
      minHeight: 45,
      padding: "0 9px 0 9px",
      color: "#505050",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      boxSizing: "border-box",
      boxShadow: "0 0px 10px 0px rgb(16 36 94 / 7%)",

      "& fieldset": {
        borderWidth: "0 !important",
      },
    },

    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
      {
        right: 11,
      },

    "& .MuiAutocomplete-popupIndicator": {
      transition: ".2s",

      "& .MuiSvgIcon-root": {
        fontSize: 20,
      },
    },
  },
}));

export const FormEntryAutocompleteFreesolo = ({
  id,
  label,
  options,
  value,
  onChange,
  onInputChange,
  getOptionLabel,
  filterOptions,
  loading,
  loadingText,
  noOptionsText,
  renderOption,
  disabled,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.container}>
      {typeof label === "object" ? (
        <InputLabel htmlFor={id}>{label}</InputLabel>
      ) : (
        <>{label}</>
      )}
      <Autocomplete
        id={id}
        freeSolo
        disableClearable
        options={options}
        value={value}
        onChange={onChange}
        onInputChange={onInputChange}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        loading={loading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" className={classes.input} />
        )}
        disabled={disabled}
        popupIcon={<ExpandMoreIcon htmlColor="#3e3e3e" />}
      />
    </FormControl>
  );
};
