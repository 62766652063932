import React, { useState, useEffect, useCallback } from "react";
import { getUser } from "../../utils/user";
import {
  makeStyles,
  Avatar,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import {
  Container,
  Title,
  CenterColumn,
  Subtitle,
  AvatarBorder,
} from "./Components.js";
import Header from "../../components/Header/Header";
import SelectInput from "../../components/SelectInput/SelectInput";
import VaccinationTimeline from "../../components/VaccinationTimeline/VaccinationTimeline";
import moment from "moment-timezone";
import axios from "axios";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
  },

  img: {
    width: 115,
    height: 115,
  },

  imgContainerUpdatedAt: {
    fontSize: 12,
    color: "#808080",
    marginTop: 10,
  },

  imgContainerDate: {
    fontSize: 10,
    color: "#000000",
    fontWeight: "bold",
  },

  optionalsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: "0px 30px",
    marginBottom: 50,
  },

  optional: {
    textAlign: "center",
    fontSize: "15px",
    color: "#808080",
    margin: "8px 0px",
  },
}));

const daysByUnit = {
  week: 7,
  month: 30,
  year: 360,
};

const Calendar = () => {
  const { t } = useTranslation("calendarPage");
  const classes = useStyles();

  const [selectedCountry, setSelectedCountry] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  const normalizeAndSortVaccines = (vaccines) => {
    try {
      const normalizedVaccines = vaccines.reduce((result, vaccine) => {
        if (vaccine.timeNumber !== undefined) {
          result.push({
            ...vaccine,
            days: vaccine.timeNumber * daysByUnit[vaccine.timeUnit],
          });
        }
        return result;
      }, []);

      const sortedVaccines = normalizedVaccines.sort((a, b) => a.days - b.days);

      return sortedVaccines;
    } catch (e) {
      console.error(e);
      return vaccines;
    }
  };

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const hasImage = (image) => {
    if (image.length > 0) {
      if (image[0].logo !== undefined) {
        if (image[0].logo.url !== undefined) {
          return image[0].logo.url;
        } else {
          return "/assets/circle-healthcare-pink.svg";
        }
      } else {
        return "/assets/circle-healthcare-pink.svg";
      }
    } else {
      return "/assets/circle-healthcare-pink.svg";
    }
  };

  const handleChange = async (event) => {
    const countryId = event.target.value;
    setLoading(true);

    try {
      const country = countries.find((country) => country.id === countryId);

      setSelectedCountry({
        country: countryId,
        vaccines: country.vaccines.sortedVaccines,
        optionals: country?.vaccines.optionals,
        healthAuthorityLogo: hasImage(country?.healthAuthorities),
        updatedAt: moment(country.updatedAt)
          .tz("America/Recife")
          .format("DD/MM/YYYY, HH:mm:ss"),
      });
    } catch (error) {
      console.log(`[Calendar handleChange Error]: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  const order = (a, b) => {
    const x = a.name.toLowerCase();
    const y = b.name.toLowerCase();

    return x > y ? 1 : x < y ? -1 : 0;
  };

  const splitVaccines = useCallback((data) => {
    let normal = [];
    let optionals = [];

    data.forEach((vac) => {
      let vaccine = {
        id: vac.id,
        name: vac.name,
        timeUnit: vac.applicationStartTimeUnit,
        timeNumber: vac.applicationStart,
      };

      if (vac.optional === true) {
        optionals.push(vaccine);
      } else {
        normal.push(vaccine);
      }
    });

    const sortedVaccines = normalizeAndSortVaccines(normal);
    let result = { sortedVaccines, optionals };

    return result;
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const user = getUser();
        let countries = [];
        let userCountryId = "";

        const { data: result } = await axios.get(
          `${process.env.REACT_APP_API_URL}/countries`
        );

        result.forEach((result) => {
          let country = {
            id: result.id,
            name: result.name,
            updatedAt: result.updatedAt,
            vaccines: splitVaccines(result.vaccines),
            healthAuthorities: result.healthAuthorities
              ? result.healthAuthorities
              : [],
          };
          countries.push(country);
        });

        const orderedCountries = countries.sort(order);
        setCountries(orderedCountries);

        if (user.country.id) {
          userCountryId = user.country.id;
        } else {
          userCountryId = user.country;
        }

        const userCountry = countries.find(
          (country) => country.id === userCountryId
        );

        setSelectedCountry({
          country: userCountry.id,
          vaccines: userCountry.vaccines.sortedVaccines,
          optionals: userCountry?.vaccines.optionals,
          healthAuthorityLogo: hasImage(userCountry?.healthAuthorities),
          updatedAt: moment(userCountry.updatedAt)
            .tz("America/Recife")
            .format("DD/MM/YYYY, HH:mm:ss"),
        });

        setLoading(false);
      } catch (error) {
        console.log(`[Calendar getCountries Error]: ${error}`);
        setLoading(false);
      }
    };
    getCountries();
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header backgroundStyle="ORANGE" />
      <Title>{t("TITLE")}</Title>
      {loading ? (
        <CenterColumn>
          <CircularProgress />
        </CenterColumn>
      ) : (
        <>
          <div className={classes.imgContainer}>
            <AvatarBorder>
              <Avatar
                src={selectedCountry?.healthAuthorityLogo}
                alt="Health Authorities Logo"
                className={classes.img}
              />
            </AvatarBorder>
            {selectedCountry?.updatedAt && (
              <>
                <Typography className={classes.imgContainerUpdatedAt}>
                  {t("CALENDAR_UPDATED_AT")}
                </Typography>
                <Typography className={classes.imgContainerDate}>
                  {selectedCountry?.updatedAt}
                </Typography>
              </>
            )}
          </div>
          <SelectInput
            value={selectedCountry?.country}
            isLoading={loading}
            onChange={handleChange}
            options={countries}
          />
          {selectedCountry?.vaccines && (
            <div style={{ marginTop: 20 }}>
              <VaccinationTimeline vaccines={selectedCountry?.vaccines} />
              <div className={classes.optionalsContainer}>
                {selectedCountry?.optionals?.length > 0 && (
                  <Subtitle>{t("OPTIONALS")}</Subtitle>
                )}
                {(selectedCountry?.optionals || []).map((optional, index) => {
                  return (
                    <p key={"optional" + index} className={classes.optional}>
                      {optional.name}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Calendar;
