import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: 5,
  },

  label: {
    cursor: "pointer",
  },

  labelContent: {
    margin: "12px 0px 8px 0px",
    fontWeight: "bold",
    color: "#505050",
  },

  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    padding: "8px 12px",
    borderRadius: "8px",
    height: "45px",
    boxSizing: "border-box",
    boxShadow: "0 0px 10px 0px rgb(16 36 94 / 7%)",
    border: "none",
    cursor: "pointer",
  },

  selectInput: {
    width: "100%",
    height: "30px",
    color: "#505050",
    backgroundColor: "#FFFFFF !important",
    border: "none",
    cursor: "pointer",
  },
}));

const FormEntrySelect = ({
  id,
  onChange,
  value,
  label,
  disabled,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {label && (
        <label htmlFor={id} className={classes.label}>
          <p className={classes.labelContent}>{label}</p>
        </label>
      )}
      <form className={classes.form}>
        <select
          id={id}
          onChange={onChange}
          value={value}
          className={classes.selectInput}
          disabled={disabled}
        >
          {children}
        </select>
      </form>
    </div>
  );
};

export default FormEntrySelect;
