import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  Title,
  CenterColumn,
  FormContainer,
  PaddingLeft,
  Button,
} from "./Components";
import Header from "../../components/Header/Header";
import FormEntrySelect from "../../components/FormEntrySelect/FormEntrySelect";
import FormEntryText from "../../components/FormEntryText/FormEntryText";
import FormEntryDate from "../../components/FormEntryDate/FormEntryDate";
import { FormEntryAutocomplete } from "../../components/FormEntryAutocomplete";
import ChooseOption from "../../components/ChooseOption/ChooseOption";
import BoxUploadFile from "../../components/BoxUploadFile/BoxUploadFile";
import { FeedbackModal } from "../../components/FeedbackModal/FeedbackModal";
import {
  makeStyles,
  FormControlLabel,
  Checkbox,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import DatePicker from "react-mobile-datepicker";

import { request } from "../../utils/request";
import { dateConfig } from "../../utils/dateConfig";
import debounce from "lodash.debounce";
import { TOCModal } from "../../components/TOCModal";
import { getUser } from "../../utils/user";
import { FormEntryAutocompleteFreesolo } from "../../components/FormEntryAutocompleteFreesolo";
import {
  TimePicker,
  ConfigProvider,
  Spin,
  Card,
  Radio,
  Input,
  Select,
  message,
  notification,
  Button as AntdButton,
  Typography as AntdTypography,
} from "antd";

const { Title: AntdTitle } = AntdTypography;

const useStyles = makeStyles(() => ({
  datepickerModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "95%",
    maxWidth: "420px",
    margin: "0 auto",
  },

  datepicker: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 320,
    "& .datepicker": {
      position: "static",
      borderRadius: 10,
      padding: 15,
    },
    "& .datepicker.default .datepicker-header": {
      fontSize: 20,
    },
    "& .datepicker.default .datepicker-navbar-btn": {
      fontSize: 18,
    },
  },
}));

const DEPENDANT_TYPE_OPTIONS = [
  {
    name: "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE_UNDER_AGE",
    value: "Menor Dependente",
  },
  {
    name: "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE_OVER_AGE",
    value: "Idoso",
  },
  {
    name: "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE_PCD",
    value: "PCD",
  },
];

const UploadMedicalCertificate = () => {
  const classes = useStyles();
  const { t } = useTranslation(["medicalCertificatePage", "common"]);

  const [isOpenTOCModal, setIsOpenTOCModal] = useState(false);
  const [isTOCChecked, setIsTOCChecked] = useState(false);

  const [enterpriseList, setEnterpriseList] = useState([]);
  const user = getUser();

  const telephoneModalOptions = [
    {
      label: "Sim",
      value: "yes",
      disabled:
        user?.mobile && user?.mobile.length === 11 && Number(user?.mobile)
          ? false
          : true,
    },
    { label: "Não", value: "no" },
  ];

  const docTypeOptions = [
    { label: "Atestado/Laudo médico", value: "sick-note" },
    { label: "Declaração de comparecimento", value: "attendance-declaration" },
    { label: "Declaração de acompanhante", value: "dependant-companion" },
  ];

  const [medicalCertificateData, setMedicalCertificateData] = useState({
    enterprise: "",
    file: "",
    doctorName: "",
    date: undefined,
    doctorRegistry: "",
    intDiseaseClassification: "",
    clinicName: "",
    clinicPhone: "",
    daysOff: 0,
    isAttendanceDeclaration: false,
    attendanceDeclarationDates: [""],
  });

  const [dependantInformation, setDependantInformation] = useState({
    dependantCompanion: false,
    dependantCompanionType: "",
    dependantName: "",
    dependantDegreeOfKinship: "",
    dependantTaxRelated: "no",
  });

  const [isLoadingUserEnterprises, setIsLoadingUserEnterprises] =
    useState(false);
  const [
    isSubmittingMedicalCertificateData,
    setIsSubmittingMedicalCertificateData,
  ] = useState(false);
  const [isUploadingMedicalCertificate, setIsUploadingMedicalCertificate] =
    useState(false);

  //Perfect cenario to use a useReducer in the future 🤔 ...
  const [searchQuery, setSearchQuery] = useState("");
  const [searchDoctorCrm, setSearchDoctorCrm] = useState("");
  const [searchDoctorName, setSearchDoctorName] = useState("");

  const [icdsOptions, setIcdsOptions] = useState([]);
  const [doctorCrmOptions, setDoctorCrmOptions] = useState([]);
  const [doctorNameOptions, setDoctorNameOptions] = useState([]);

  const [isLoadingIcds, setIsLoadingIcds] = useState(false);
  const [isLoadingDocCrm, setIsLoadingDocCrm] = useState(false);
  const [isLoadingDocName, setIsLoadingDocName] = useState(false);

  const [isBRFUser, setIsBRFUser] = useState(false);
  const [enterpriseClinics, setEnterpriseClinics] = useState([]);
  const [selectedEnterprises, setSelectedEnterprises] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [isOpenNoEnterpriseFoundModal, setIsOpenNoEnterpriseFoundModal] =
    useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenSuccessSubmitModal, setIsOpenSuccessSubmitModal] =
    useState(false);

  const [openConfirmTelephoneModal, setOpenConfirmTelephoneModal] =
    useState(false);
  const [selectedTelephoneModalOption, setSelectedTelephoneModalOption] =
    useState();
  const [newTelephoneNumberValue, setNewTelephoneNumberValue] = useState(
    user?.mobile ? user?.mobile : 0
  );
  const [telephoneNumberWasChecked, setTelephoneNumberWasChecked] =
    useState(false);
  const [isSubmitingNewNumber, setIsSubmitingNewNumber] = useState(false);
  const [selectedDocumentType, setSelectedDocumentType] = useState("sick-note");
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationApi, contextHolderNotification] =
    notification.useNotification();

  const isAllFieldsFilled = () => {
    if (dependantInformation.dependantCompanion) {
      if (
        !dependantInformation.dependantCompanionType ||
        dependantInformation.dependantName.trim().length === 0 ||
        dependantInformation.dependantDegreeOfKinship.trim().length === 0
      ) {
        messageApi.error({
          content: (
            <span style={{ fontSize: 14.5 }}>
              {t("UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_ERROR")}
            </span>
          ),
          duration: 5000,
          key: "error",
        });

        setTimeout(() => messageApi.destroy("error"), 5000);
        return false;
      }
    }

    if (selectedDocumentType !== "sick-note") {
      if (medicalCertificateData.attendanceDeclarationDates.length <= 1) {
        messageApi.error({
          content: (
            <span style={{ fontSize: 14.5 }}>
              Insira um horário de atendimento válido
            </span>
          ),
          duration: 5000,
          key: "error",
        });

        setTimeout(() => messageApi.destroy("error"), 5000);
        return false;
      }
    }

    if (
      !selectedEnterprises.length ||
      !medicalCertificateData.date ||
      !medicalCertificateData.file
    ) {
      messageApi.error({
        content: (
          <span style={{ fontSize: 14.5 }}>
            {t("UPLOAD_MEDICAL_CERTIFICATE_MISSING_FIELD_ERROR")}
          </span>
        ),
        duration: 5000,
        key: "error",
      });

      setTimeout(() => messageApi.destroy("error"), 5000);
      return false;
    }

    //only required if the document is a sick note
    if (
      !medicalCertificateData.clinicName.trim().length === 0 &&
      selectedDocumentType === "sick-note"
    ) {
      messageApi.error({
        content: (
          <span style={{ fontSize: 14.5 }}>
            {t("UPLOAD_MEDICAL_CERTIFICATE_MISSING_FIELD_ERROR")}
          </span>
        ),
        duration: 5000,
        key: "error",
      });

      setTimeout(() => messageApi.destroy("error"), 5000);
      return false;
    }

    return true;
  };

  const updateMedicalCertificateData = (field, value) => {
    if (field === "daysOff") {
      //never gonna happen
      if (value < 0) {
        messageApi.error({
          content: (
            <span style={{ fontSize: 14.5 }}>
              {t("UPLOAD_MEDICAL_CERTIFICATE_DAYS_OFF_ERROR")}
            </span>
          ),
          duration: 5000,
          key: "error",
        });

        setTimeout(() => messageApi.destroy("error"), 5000);
        return;
      }
    }

    const updatedMedicalCertificateData = { ...medicalCertificateData };
    updatedMedicalCertificateData[field] = value;
    setMedicalCertificateData(updatedMedicalCertificateData);
  };

  const updateDependantInformation = (field, value) => {
    const updatedDependantInformation = { ...dependantInformation };
    updatedDependantInformation[field] = value;
    setDependantInformation(updatedDependantInformation);
  };

  const uploadMedicalCertificateFile = async (file) => {
    try {
      setIsUploadingMedicalCertificate(true);
      const formData = new FormData();
      formData.append(`file.medicalCertificate`, file);
      const imageResult = await request.post(
        `/client-app/upload-covid-document?documentType=medicalCertificate`,
        formData
      );

      const updatedMedicalCertificateData = { ...medicalCertificateData };
      updatedMedicalCertificateData["file"] = imageResult?.data?.fileId;
      setMedicalCertificateData(updatedMedicalCertificateData);
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploadingMedicalCertificate(false);
    }
  };

  const onChangeIcd = (_, value) => {
    const updatedMedicalCertificateData = { ...medicalCertificateData };
    updatedMedicalCertificateData.intDiseaseClassification = value;
    setMedicalCertificateData(updatedMedicalCertificateData);
  };

  const onChangeDocName = (test, value) => {
    const updatedMedicalCertificateData = { ...medicalCertificateData };
    updatedMedicalCertificateData.doctorName = value;
    updatedMedicalCertificateData.doctorRegistry = value;
    setMedicalCertificateData(updatedMedicalCertificateData);
  };

  const onChangeDocCRM = (_, value) => {
    const updatedMedicalCertificateData = { ...medicalCertificateData };
    updatedMedicalCertificateData.doctorName = value;
    updatedMedicalCertificateData.doctorRegistry = value;
    setMedicalCertificateData(updatedMedicalCertificateData);
  };

  const onIcdInputChange = (_, value) => {
    setSearchQuery(value);
  };

  const renderIcdOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12}>
          {`${option.code} - ${option.description["PT"]}`}
        </Grid>
      </Grid>
    );
  };

  const filterIcdsOptions = (options) => options;

  const getIcdsOptionLabel = (option) => {
    if (!option) {
      return "";
    }

    return `${option.code} - ${option.description["PT"]}`;
  };

  const getDocNameOptionLabel = (option) => {
    if (!option) {
      return "";
    }

    return `${option.name} - ${option.crm}`;
  };

  const getDocCrmOptionLabel = (option) => {
    if (!option) {
      return "";
    }

    return `${option.crm} - ${option.name}`;
  };

  const getIcdsOptionsAsync = async (query) => {
    try {
      if (query === "") {
        return [];
      }

      const { data: queryResult } = await request.get(
        `/client-app/find-icds?code=${query.toLowerCase()}`
      );

      return queryResult;
    } catch (err) {
      console.error(`[getIcdsOptionsAsync] ${err}`);
      return [];
    }
  };

  const getDoctorNameOptionsAsync = async (query) => {
    try {
      if (query === "") {
        return [];
      }

      const { data: queryResult } = await request.get(
        `/sick-notes-doctors/query?name=${query.toLowerCase()}`
      );

      return queryResult;
    } catch (err) {
      console.error(`[getIcdsOptionsAsync] ${err}`);
      return [];
    }
  };

  const getDoctorCrmOptionsAsync = async (query) => {
    try {
      if (query === "") {
        return [];
      }

      const { data: queryResult } = await request.get(
        `/sick-notes-doctors/query?crm=${query.toLowerCase()}`
      );

      return queryResult;
    } catch (err) {
      console.error(`[getIcdsOptionsAsync] ${err}`);
      return [];
    }
  };

  const getIcdsOptionsDelayed = useCallback(
    debounce((query, callback) => {
      setIcdsOptions([]);
      getIcdsOptionsAsync(query).then(callback);
    }, 300),
    []
  );

  const getDoctorNameOptionsDelayed = useCallback(
    debounce((query, callback) => {
      setDoctorNameOptions([]);
      getDoctorNameOptionsAsync(query).then(callback);
    }, 300),
    []
  );

  const getDoctorCrmOptionsDelayed = useCallback(
    debounce((query, callback) => {
      setIcdsOptions([]);
      getDoctorCrmOptionsAsync(query).then(callback);
    }, 300),
    []
  );

  const handleSubmitMedicalCertificateData = async () => {
    try {
      setIsSubmittingMedicalCertificateData(true);
      if (!isAllFieldsFilled()) {
        return;
      }

      //never gonna happen, because it's already checked in the "isAllFieldsFilled" func
      if (selectedEnterprises.length === 0) {
        messageApi.error({
          content: (
            <span style={{ fontSize: 14.5 }}>
              Selecione ao menos uma empresa para compartilhar o atestado
            </span>
          ),
          duration: 5000,
          key: "error",
        });

        setTimeout(() => messageApi.destroy("error"), 5000);
        return;
      }

      // Checar se a IMIP foi selecionada
      const isImipSelected = selectedEnterprises.some(
        (ent) => ent === "657b0373f3693a001a0477c2"
      );
      if (isImipSelected) {
        // Checar se o usuário ja esta afastado, se estiver mostrar mensagem especifica
        if (user?.currentlyLongTermSickLeave) {
          messageApi.error({
            content: (
              <span style={{ fontSize: 14.5 }}>
                Este atestado/laudo deve ser entregue presencialmente ao SESMT
                do IMIP
              </span>
            ),
            duration: 5000,
            key: "error",
          });

          setTimeout(() => messageApi.destroy("error"), 5000);
        }
      }

      // check if the user already has uploaded a sick note, and that sick notes still peding
      const { data: userPreviousSickNotes } = await request.get(
        `/client-app/find-user-sick-notes`
      );

      if (userPreviousSickNotes?.length) {
        const userPreviousSickNotesFiltered = userPreviousSickNotes.filter(
          (sickNote) => sickNote.status === "PENDING"
        );

        if (userPreviousSickNotesFiltered?.length) {
          notificationApi.info({
            message: (
              <span style={{ fontWeight: 600 }}>Atestado em análise!</span>
            ),
            description: (
              <span style={{ fontSize: 14.5 }}>
                Você já possui um atestado pendente de análise, por favor,
                aguarde a análise do mesmo antes de enviar um novo.
              </span>
            ),
            placement: "top",
            closeIcon: false,
            showProgress: true,
            duration: 10,
          });

          return;
        }
      }

      // for each enterprise selected, create a new medical certificate
      for await (const enterprise of selectedEnterprises) {
        if (!medicalCertificateData.clinicPhone) {
          //only if doc is a sick note
          medicalCertificateData.clinicPhone =
            selectedDocumentType === "sick-note" ? "NÃO INFORMADO" : "";
        }

        let body = {
          ...medicalCertificateData,
          enterprise, // for compatibility with old code,
          doctorName: medicalCertificateData.doctorName
            ? medicalCertificateData.doctorName.name
            : searchDoctorName,
          doctorRegistry: medicalCertificateData.doctorRegistry
            ? medicalCertificateData.doctorRegistry.crm
            : searchDoctorCrm,
          intDiseaseClassification:
            medicalCertificateData?.intDiseaseClassification?.code,
          date: new Date(medicalCertificateData?.date),
          kindInformedByUser: selectedDocumentType,
        };

        if (dependantInformation.dependantCompanion) {
          body = {
            ...body,
            ...dependantInformation,
            dependantTaxRelated:
              dependantInformation?.dependantTaxRelated === "no" ? false : true,
          };
        }

        if (
          selectedDocumentType === "sick-note" &&
          (!body.doctorName || !body.doctorRegistry || !body.clinicName)
        ) {
          messageApi.error({
            content: (
              <span style={{ fontSize: 14.5 }}>
                {t("UPLOAD_MEDICAL_CERTIFICATE_MISSING_FIELD_ERROR")}
              </span>
            ),
            duration: 5000,
            key: "error",
          });

          setTimeout(() => messageApi.destroy("error"), 5000);
          return false;
        }

        await request.post("/client-app/sick-note", body);
      }

      setIsOpenSuccessSubmitModal(true);
    } catch (error) {
      console.error(error);
      setIsOpenErrorModal(true);
    } finally {
      setIsSubmittingMedicalCertificateData(false);
    }
  };

  useEffect(() => {
    setIsLoadingIcds(true);

    getIcdsOptionsDelayed(searchQuery, (options) => {
      setIcdsOptions(options);

      setIsLoadingIcds(false);
    });
  }, [searchQuery, getIcdsOptionsDelayed]);

  useEffect(() => {
    setIsLoadingDocName(true);

    getDoctorNameOptionsDelayed(searchDoctorName, (options) => {
      setDoctorNameOptions(options);

      setIsLoadingDocName(false);
    });
  }, [searchDoctorName, getDoctorNameOptionsDelayed]);

  useEffect(() => {
    setIsLoadingDocCrm(true);

    getDoctorCrmOptionsDelayed(searchDoctorCrm, (options) => {
      setDoctorCrmOptions(options);

      setIsLoadingDocCrm(false);
    });
  }, [searchDoctorCrm, getDoctorCrmOptionsDelayed]);

  useEffect(() => {
    const getUserEnterprises = async () => {
      setIsLoadingUserEnterprises(true);
      try {
        const { data: resultEnterprises } = await request.get(
          `/client-app/find-user-enterprises`
        );

        // Check if user have brf enterprise
        for (const data of resultEnterprises) {
          if (data._id === "64b9554f8e8a6700131d6613") {
            // VERIFICAR SE O USER FAZ PARTE DA BRF
            setIsBRFUser(true);
            const sortedClinics = data.clinics.sort(
              (a, b) => a.commercialName > b.commercialName
            );
            sortedClinics.push({
              _id: "999999999",
              commercialName: "Outra não listada",
            });
            setEnterpriseClinics(sortedClinics);
          }
        }

        if (resultEnterprises?.length) {
          // filter only enterprises that have SICK_NOTES_UPLOAD feature
          const filteredEnterprises = resultEnterprises.filter((enterprise) =>
            enterprise.features.includes("SICK_NOTES_UPLOAD")
          );
          setEnterpriseList(
            filteredEnterprises.map((enterprise) => ({
              name: enterprise.name,
              value: enterprise._id,
            }))
          );
        } else {
          setIsOpenNoEnterpriseFoundModal(true);
          return;
        }

        if (!isTOCChecked) {
          setIsOpenTOCModal(true);
        }
      } catch (error) {
        console.log(`[UploadMedicalCertificate getUserEnterprises] ${error}`);
      } finally {
        setIsLoadingUserEnterprises(false);
      }
    };

    getUserEnterprises();

    //eslint-disable-next-line
  }, []);

  const onNewTelephoneNumberChange = (text) => {
    if (text) setNewTelephoneNumberValue(text.replace(/[^0-9]/g, ""));
  };

  const handleNewTelephoneButtonClick = async () => {
    try {
      setIsSubmitingNewNumber(true);

      await request.post(`/client-app/update-profile`, {
        id: user._id,
        newNumber: newTelephoneNumberValue,
        type: "number",
      });

      messageApi.success({
        content: (
          <span style={{ fontSize: 14.5 }}>Número atualizado com sucesso!</span>
        ),
        duration: 2500,
        key: "sucess",
      });

      setTimeout(() => messageApi.destroy("sucess"), 2500);
      setTelephoneNumberWasChecked(true);
      setOpenConfirmTelephoneModal(false);
      handleSubmitMedicalCertificateData();
    } catch (error) {
      console.log(`[handleNewTelephoneButtonClick] ${error}`);
      setOpenConfirmTelephoneModal(false);
    } finally {
      setIsSubmitingNewNumber(false);
    }
  };

  const selectDaysOffOptions = Array.from(Array(366).keys())
    .map((value) => {
      return {
        label: value <= 1 ? `${value} DIA` : `${value} DIAS`,
        value,
      };
    })
    .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0));

  return (
    <Container>
      {contextHolder}
      {contextHolderNotification}
      <ConfigProvider
        theme={{
          components: {
            Card: {
              paddingLG: 0,
            },
            Spin: {
              colorPrimary: "#ffffff",
            },
            Button: {
              colorBorder: "#2d80c6",
            },
            Select: {
              colorBorder: "#ffffff",
              hoverBorderColor: "#ffffff",
            },
          },
        }}
      >
        <Header backgroundStyle="BLUE" />
        <Title>{t("UPLOAD_MEDICAL_CERTIFICATE")}</Title>
        {isLoadingUserEnterprises ? (
          <CenterColumn>
            <CircularProgress />
          </CenterColumn>
        ) : (
          <FormContainer>
            <Typography style={{ fontWeight: "bold", color: "#505050" }}>
              Empresas <span style={{ color: "red" }}>*</span>
            </Typography>
            <Typography
              style={{ fontSize: "12px", fontWeight: "bold", color: "#808080" }}
            >
              Selecione a/as empresa(s) com a(s) quais o atestado será
              compartilhado
            </Typography>
            <PaddingLeft marginTop="12px">
              {enterpriseList?.map((enterprise) => (
                <FormControlLabel
                  key={enterprise.value}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        if (!e.target.checked) {
                          const updatedSelectedEnterprises =
                            selectedEnterprises.filter(
                              (selectedEnterprise) =>
                                selectedEnterprise !== enterprise.value
                            );
                          setSelectedEnterprises(updatedSelectedEnterprises);
                        } else {
                          setSelectedEnterprises([
                            ...selectedEnterprises,
                            enterprise.value,
                          ]);
                        }
                      }}
                      name="enterprise"
                      checked={selectedEnterprises.some(
                        (selectedEnterprise) =>
                          selectedEnterprise === enterprise.value
                      )}
                      disabled={isSubmittingMedicalCertificateData}
                    />
                  }
                  label={enterprise.name}
                />
              ))}
            </PaddingLeft>
            <p
              style={{
                marginTop: "5px !important",
                marginLeft: "8px",
                color: "#505050",
                fontWeight: "bold",
                marginBottom: "9px",
              }}
            >
              Tipo de documento <span style={{ color: "red" }}>*</span>
            </p>
            <Select
              showSearch
              style={{
                marginRight: 5,
                marginLeft: 5,
                marginBottom: 5,
                boxShadow: "0 0px 10px 0px rgb(16 36 94 / 7%)",
                height: 46,
              }}
              value={selectedDocumentType}
              placeholder="Search to Select"
              optionFilterProp="label"
              size="large"
              filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                  .toLowerCase()
                  .localeCompare((optionB?.label ?? "").toLowerCase())
              }
              options={docTypeOptions}
              onSelect={(value) => {
                setSelectedDocumentType(value);

                if (value === "dependant-companion") {
                  setDependantInformation({
                    ...dependantInformation,
                    dependantCompanion: true,
                  });

                  updateMedicalCertificateData(
                    "isAttendanceDeclaration",
                    false
                  );
                } else if (value === "attendance-declaration") {
                  setDependantInformation({
                    ...dependantInformation,
                    dependantCompanion: false,
                  });

                  updateMedicalCertificateData("isAttendanceDeclaration", true);
                } else {
                  setDependantInformation({
                    ...dependantInformation,
                    dependantCompanion: false,
                  });

                  updateMedicalCertificateData(
                    "isAttendanceDeclaration",
                    false
                  );
                }
              }}
            />
            <p
              style={{
                color: "#505050",
                fontWeight: "bold",
                marginTop: "0px !important",
                marginLeft: "8px",
                marginBottom: "8px",
              }}
            >
              {selectedDocumentType !== "sick-note" ? (
                <>
                  Data da declaração <span style={{ color: "red" }}>*</span>
                </>
              ) : (
                <>
                  Data do atestado/laudo médico{" "}
                  <span style={{ color: "red" }}>*</span>
                </>
              )}
            </p>
            <FormEntryDate
              id="date"
              value={medicalCertificateData?.date ?? null}
              onClick={() => setOpenDatePicker(true)}
              disabled={isSubmittingMedicalCertificateData}
            />
            {selectedDocumentType !== "dependant-companion" && (
              <>
                <p
                  style={{
                    marginTop: "0px !important",
                    marginLeft: "8px",
                    marginBottom: "8px",
                    color: "#505050",
                    fontWeight: "bold",
                  }}
                  className="makeStyles-labelContent-95"
                >
                  {t("UPLOAD_MEDICAL_CERTIFICATE_DOCTOR_NAME")}{" "}
                  {selectedDocumentType === "sick-note" && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </p>
                <FormEntryAutocompleteFreesolo
                  id="sicknotedocname"
                  options={doctorNameOptions}
                  value={medicalCertificateData?.doctorName}
                  onChange={onChangeDocName}
                  onInputChange={(_, value) => setSearchDoctorName(value)}
                  getOptionLabel={getDocNameOptionLabel}
                  filterOptions={filterIcdsOptions}
                  loading={isLoadingDocName}
                  loadingText={t("UPLOAD_MEDICAL_CERTIFICATE_LOADING")}
                  noOptionsText={
                    searchDoctorName === ""
                      ? t(
                          "UPLOAD_MEDICAL_CERTIFICATE_TYPE_DISEASE_CLASSIFICATION_CODE"
                        )
                      : t("UPLOAD_MEDICAL_CERTIFICATE_OPTION_NOT_FOUND")
                  }
                  renderOption={(option) => {
                    return (
                      <Grid container>
                        <Grid item>
                          {`${option.name}`}
                          <Divider
                            style={{
                              marginTop: 5,
                              marginBottom: 5,
                            }}
                          />
                          {`CRM: ${option.crm}`}
                        </Grid>
                      </Grid>
                    );
                  }}
                  disabled={isSubmittingMedicalCertificateData}
                />
                <p
                  style={{
                    marginTop: "0px !important",
                    marginLeft: "8px",
                    marginBottom: "8px",
                    color: "#505050",
                    fontWeight: "bold",
                  }}
                  className="makeStyles-labelContent-95"
                >
                  {t("UPLOAD_MEDICAL_CERTIFICATE_DOCTOR_REGISTRY")}{" "}
                  {selectedDocumentType === "sick-note" && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </p>
                <FormEntryAutocompleteFreesolo
                  id="sicknotedoccrm"
                  options={doctorCrmOptions}
                  value={medicalCertificateData?.doctorRegistry}
                  onChange={onChangeDocCRM}
                  onInputChange={(_, value) => setSearchDoctorCrm(value)}
                  getOptionLabel={getDocCrmOptionLabel}
                  filterOptions={filterIcdsOptions}
                  loading={isLoadingDocCrm}
                  loadingText={t("UPLOAD_MEDICAL_CERTIFICATE_LOADING")}
                  noOptionsText={
                    searchDoctorCrm === ""
                      ? t(
                          "UPLOAD_MEDICAL_CERTIFICATE_TYPE_DISEASE_CLASSIFICATION_CODE"
                        )
                      : t("UPLOAD_MEDICAL_CERTIFICATE_OPTION_NOT_FOUND")
                  }
                  renderOption={(option) => {
                    return (
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          {`${option.crm}`}
                        </Grid>
                      </Grid>
                    );
                  }}
                  disabled={isSubmittingMedicalCertificateData}
                />
                <FormEntryAutocomplete
                  id="intDiseaseClassification"
                  label={t("UPLOAD_MEDICAL_CERTIFICATE_DISEASE_CLASSIFICATION")}
                  options={icdsOptions}
                  value={medicalCertificateData?.intDiseaseClassification}
                  onChange={onChangeIcd}
                  onInputChange={onIcdInputChange}
                  getOptionLabel={getIcdsOptionLabel}
                  filterOptions={filterIcdsOptions}
                  loading={isLoadingIcds}
                  loadingText={t("UPLOAD_MEDICAL_CERTIFICATE_LOADING")}
                  noOptionsText={
                    searchQuery === ""
                      ? t(
                          "UPLOAD_MEDICAL_CERTIFICATE_TYPE_DISEASE_CLASSIFICATION_CODE"
                        )
                      : t("UPLOAD_MEDICAL_CERTIFICATE_OPTION_NOT_FOUND")
                  }
                  renderOption={renderIcdOption}
                  disabled={isSubmittingMedicalCertificateData}
                />

                {isBRFUser ? (
                  <>
                    <FormEntrySelect
                      id="clinicName"
                      onChange={(event) =>
                        updateMedicalCertificateData(
                          "clinicName",
                          event.target.value
                        )
                      }
                      label={t("UPLOAD_MEDICAL_CERTIFICATE_CLINIC_NAME")}
                      value={medicalCertificateData?.clinicName}
                      disabled={isSubmittingMedicalCertificateData}
                    >
                      <option value="">
                        {t("SELECT_AN_OPTION", { ns: "common" })}
                      </option>
                      {enterpriseClinics?.map((clinic) => (
                        <option key={clinic._id} value={clinic.commercialName}>
                          {clinic.commercialName}
                        </option>
                      ))}
                    </FormEntrySelect>
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        marginTop: "0px !important",
                        marginLeft: "8px",
                        marginBottom: "8px",
                        color: "#505050",
                        fontWeight: "bold",
                      }}
                      className="makeStyles-labelContent-95"
                    >
                      {t("UPLOAD_MEDICAL_CERTIFICATE_CLINIC_NAME")}{" "}
                      {selectedDocumentType === "sick-note" && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </p>
                    <FormEntryText
                      id="clinicName"
                      onChange={(event) =>
                        updateMedicalCertificateData(
                          "clinicName",
                          event.target.value
                        )
                      }
                      value={medicalCertificateData?.clinicName}
                      disabled={isSubmittingMedicalCertificateData}
                    />
                  </>
                )}

                <FormEntryText
                  id="clinicPhone"
                  label={t("UPLOAD_MEDICAL_CERTIFICATE_CLINIC_PHONE")}
                  onChange={(event) =>
                    updateMedicalCertificateData(
                      "clinicPhone",
                      event.target.value
                    )
                  }
                  value={medicalCertificateData?.clinicPhone}
                  disabled={isSubmittingMedicalCertificateData}
                />
              </>
            )}
            {selectedDocumentType === "sick-note" && (
              <>
                <p
                  style={{
                    marginTop: "5px !important",
                    marginLeft: "8px",
                    color: "#505050",
                    fontWeight: "bold",
                    marginBottom: "7px",
                  }}
                >
                  {t("UPLOAD_MEDICAL_CERTIFICATE_DAYS_OFF")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </p>

                <Select
                  size="large"
                  style={{
                    marginRight: 5,
                    marginLeft: 5,
                    marginBottom: 5,
                    boxShadow: "0 0px 10px 0px rgb(16 36 94 / 7%)",
                    height: 46,
                  }}
                  defaultValue={0}
                  optionFilterProp="label"
                  placeholder="Selecione uma data"
                  value={medicalCertificateData?.daysOff}
                  disabled={isSubmittingMedicalCertificateData}
                  onSelect={(daysOff) =>
                    updateMedicalCertificateData("daysOff", daysOff)
                  }
                  options={selectDaysOffOptions}
                />

                {/* <FormEntrySelect
                  id="enterprise"
                  onChange={(event) =>
                    updateMedicalCertificateData("daysOff", event.target.value)
                  }
                >
                  <option value="">Selecione uma data</option>
                  {Array.from(Array(366).keys()).map((value) => (
                    <option key={value} value={value}>
                      {value <= 1 ? `${value} DIA` : `${value} DIAS`}
                    </option>
                  ))}
                </FormEntrySelect> */}
              </>
            )}
            {selectedDocumentType !== "sick-note" && (
              <>
                <Typography
                  style={{
                    marginTop: "16px",
                    marginLeft: 8,
                    marginBottom: "8px",
                    color: "#505050",
                    fontWeight: "bold",
                  }}
                >
                  Selecione o horário de atendimento{" "}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TimePicker.RangePicker
                  format="HH:mm"
                  size="large"
                  placeholder={["Hora de início", "Hora de término"]}
                  style={{
                    margin: "0px 5.5px 0px 5.5px",
                    backgroundColor: "white",
                    boxShadow: "0 0px 10px 0px rgb(16 36 94 / 7%)",
                    height: 48,
                  }}
                  variant="filled"
                  order={false}
                  needConfirm={false}
                  onChange={(_, date) =>
                    updateMedicalCertificateData(
                      "attendanceDeclarationDates",
                      date
                    )
                  }
                  disabled={isSubmittingMedicalCertificateData}
                />
              </>
            )}
            {selectedDocumentType === "dependant-companion" && (
              <>
                <FormEntrySelect
                  id="dependantCompanionType"
                  label={t(
                    "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TYPE"
                  )}
                  onChange={(event) =>
                    updateDependantInformation(
                      "dependantCompanionType",
                      event.target.value
                    )
                  }
                  value={dependantInformation?.dependantCompanionType}
                  disabled={isSubmittingMedicalCertificateData}
                >
                  <option value="">
                    {t("SELECT_AN_OPTION", { ns: "common" })}
                  </option>
                  {DEPENDANT_TYPE_OPTIONS?.map((dependantType) => (
                    <option
                      key={dependantType.value}
                      value={dependantType.value}
                    >
                      {t(dependantType.name)}
                    </option>
                  ))}
                </FormEntrySelect>

                <FormEntryText
                  id="dependantName"
                  label={t(
                    "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_NAME"
                  )}
                  onChange={(event) =>
                    updateDependantInformation(
                      "dependantName",
                      event.target.value
                    )
                  }
                  value={dependantInformation?.dependantName}
                  disabled={isSubmittingMedicalCertificateData}
                />

                <FormEntryText
                  id="dependantDegreeOfKinship"
                  label={t(
                    "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_DEGREE_OF_KINSHIP"
                  )}
                  onChange={(event) =>
                    updateDependantInformation(
                      "dependantDegreeOfKinship",
                      event.target.value
                    )
                  }
                  value={dependantInformation?.dependantDegreeOfKinship}
                  disabled={isSubmittingMedicalCertificateData}
                />

                <ChooseOption
                  title={t(
                    "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_TAX_RELATED"
                  )}
                  name="dependantTaxRelated"
                  toCheckData={dependantInformation?.dependantTaxRelated}
                  onChange={(event) =>
                    updateDependantInformation(
                      "dependantTaxRelated",
                      event.target.value
                    )
                  }
                  firstOptionId="yes"
                  firstOptionValue="yes"
                  firstOptionLabel={t(
                    "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_YES"
                  )}
                  secondOptionId="no"
                  secondOptionValue="no"
                  secondOptionLabel={t(
                    "UPLOAD_MEDICAL_CERTIFICATE_DEPENDANT_COMPANION_NO"
                  )}
                  disabled={isSubmittingMedicalCertificateData}
                />
              </>
            )}

            <PaddingLeft>
              <BoxUploadFile
                marginTop={15}
                id="document-file-uploader"
                title={
                  <p>
                    Imagem do documento <span style={{ color: "red" }}>*</span>
                  </p>
                }
                buttonText={t("UPLOAD_MEDICAL_CERTIFICATE_UPLOAD_FILE")}
                buttonTextWhenDocumentUploaded={t(
                  "UPLOAD_MEDICAL_CERTIFICATE_UPLOADED_FILE_BUTTON_TEXT"
                )}
                documentUploadedText={t(
                  "UPLOAD_MEDICAL_CERTIFICATE_UPLOADED_FILE_TEXT"
                )}
                isLoading={isUploadingMedicalCertificate}
                displayOnly={medicalCertificateData?.["file"]}
                componentType="anyFile"
                secondaryColor
                onChange={(file) => uploadMedicalCertificateFile(file)}
              />
            </PaddingLeft>

            {/* hacky way off apply margin because for some reason the margin seems to not working on antd button */}
            <div style={{ marginBottom: "90px !important" }}>
              <p style={{ color: "white" }}>c</p>
            </div>

            <AntdButton
              shape="round"
              variant="text"
              onClick={() =>
                telephoneNumberWasChecked
                  ? handleSubmitMedicalCertificateData()
                  : setOpenConfirmTelephoneModal(true)
              }
              disabled={
                isUploadingMedicalCertificate ||
                isSubmittingMedicalCertificateData
              }
              style={{
                paddingTop: "50px !important",
                width: "140px",
                color: "white",
                fontWeight: 600,
                margin: "0 auto",
                border: "0px !important",
                backgroundColor: "#2d80c6",
              }}
            >
              {!isSubmittingMedicalCertificateData ? (
                t("UPLOAD_MEDICAL_CERTIFICATE_BUTTON_TITLE").toUpperCase()
              ) : (
                <Spin />
              )}
            </AntdButton>
          </FormContainer>
        )}

        <Modal
          aria-labelledby="date-modal-title"
          aria-describedby="date-modal-description"
          className={classes.datepickerModal}
          open={openConfirmTelephoneModal}
          onClose={() => setOpenConfirmTelephoneModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openConfirmTelephoneModal}>
            <Card
              title={
                <p style={{ padding: "3px 16px 3px 16px", fontSize: 20 }}>
                  Confirme seu número de telefone
                </p>
              }
              bordered={false}
              style={{ width: 400 }}
            >
              <div style={{ padding: "10px 16px 10px 16px", fontSize: 16 }}>
                <p>
                  Precisamos que você confirme o seu número de telefone antes de
                  enviar um atestado.
                </p>
                <p>
                  Seu número atual ainda é:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {user?.mobile ? user?.mobile : "Sem registro"}
                  </span>{" "}
                  ?
                </p>
                <Radio.Group
                  block
                  options={telephoneModalOptions}
                  optionType="button"
                  buttonStyle="solid"
                  onChange={(e) => {
                    setSelectedTelephoneModalOption(e.target.value);

                    if (e.target.value === "yes") {
                      setTelephoneNumberWasChecked(true);
                      setOpenConfirmTelephoneModal(false);
                      handleSubmitMedicalCertificateData();
                    }
                  }}
                  size="large"
                  style={{ marginBottom: 10 }}
                />

                {selectedTelephoneModalOption &&
                  selectedTelephoneModalOption === "no" && (
                    <>
                      <AntdTitle level={5}>
                        Insira um novo número de telefone válido
                      </AntdTitle>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Input
                          style={{
                            width: "100%",
                            marginBottom: !(
                              newTelephoneNumberValue !== 0 &&
                              newTelephoneNumberValue !== user?.mobile
                            )
                              ? 10
                              : 0,
                          }}
                          size="large"
                          placeholder="Número de telefone"
                          defaultValue={newTelephoneNumberValue}
                          onChange={(val) => {
                            onNewTelephoneNumberChange(val.target.value);
                          }}
                        />

                        {newTelephoneNumberValue !== 0 &&
                          newTelephoneNumberValue !== user?.mobile && (
                            <AntdButton
                              size="large"
                              variant="filled"
                              onClick={() => handleNewTelephoneButtonClick()}
                              disabled={
                                newTelephoneNumberValue.length < 11 ||
                                isSubmitingNewNumber
                              }
                              style={{
                                marginTop: 20,
                                marginBottom: 10,
                                backgroundColor: "#2d80c6",
                                color: "white",
                              }}
                            >
                              {!isSubmitingNewNumber ? "Salvar" : <Spin />}
                            </AntdButton>
                          )}
                      </div>
                    </>
                  )}
              </div>
            </Card>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="date-modal-title"
          aria-describedby="date-modal-description"
          className={classes.datepickerModal}
          open={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openDatePicker}>
            <div className={classes.datepicker}>
              <DatePicker
                value={medicalCertificateData?.date}
                onSelect={(date) => {
                  updateMedicalCertificateData("date", date);
                  setOpenDatePicker(false);
                }}
                onCancel={() => setOpenDatePicker(false)}
                isPopup={false}
                showHeader={true}
                headerFormat="DD/MM/YYYY"
                dateConfig={dateConfig}
                min={new Date(1910, 0, 1)}
                max={new Date()}
                confirmText={t("UPLOAD_MEDICAL_CERTIFICATE_DATE_MODAL_CONFIRM")}
                cancelText={t("UPLOAD_MEDICAL_CERTIFICATE_DATE_MODAL_CANCEL")}
              />
            </div>
          </Fade>
        </Modal>

        <TOCModal
          id="toc-modal-medical-certificate"
          open={isOpenTOCModal}
          content={{
            title: t("UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_TITLE"),
            description: t("UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_DESCRIPTION"),
            checkboxLabel: t(
              "UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_CONFIRMATION_TEXT"
            ),
            backButtonTitle: t("UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_CANCEL"),
            confirmButtonTitle: t(
              "UPLOAD_MEDICAL_CERTIFICATE_TOC_MODAL_CONFIRM"
            ),
          }}
          checked={isTOCChecked}
          onClick={() => setIsOpenTOCModal(false)}
          onChange={() => setIsTOCChecked(!isTOCChecked)}
        />

        <FeedbackModal
          id="no-enterprise-found"
          title={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_MODAL_TITLE"),
          }}
          description={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_NO_ENTERPRISE_MODAL_CONTENT"),
          }}
          button={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_MODAL_BUTTON"),
          }}
          open={isOpenNoEnterpriseFoundModal}
          onClick={() => {
            setIsOpenNoEnterpriseFoundModal(false);
            window.location.replace("/secure");
          }}
        />

        <FeedbackModal
          id="error"
          title={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_MODAL_TITLE"),
          }}
          description={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_ERROR_MODAL_CONTENT"),
          }}
          button={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_MODAL_BUTTON"),
          }}
          open={isOpenErrorModal}
          onClick={() => {
            setIsOpenErrorModal(false);
            window.location.replace("/secure");
          }}
        />

        <FeedbackModal
          id="submit-success"
          title={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_MODAL_TITLE"),
          }}
          description={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_SUCCESS_MODAL_CONTENT"),
          }}
          button={{
            text: t("UPLOAD_MEDICAL_CERTIFICATE_MODAL_BUTTON"),
            backgroundColor: "#00A859",
          }}
          open={isOpenSuccessSubmitModal}
          onClick={() => {
            setIsOpenSuccessSubmitModal(false);
            window.location.replace(
              "/secure/my-health/medical-certificate/consult-certificate"
            );
          }}
        />
      </ConfigProvider>
    </Container>
  );
};

export default UploadMedicalCertificate;
